import React, { useEffect } from 'react';
import { Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import useLocales from '#/hooks/useLocales';
import { RHFTextField } from '#/components/shared/hook-form';
import ahvFunds from '#/components/pages/ImportantInformation/steps/FinancialInformations/ahvFunds';
import RHFAutocomplete from '#/components/shared/hook-form/RHFAutocomplete';
import useLocalStorage from '#/hooks/useLocalStorage';

export default function StatePension() {
  const { translate } = useLocales();
  const { state } = useLocation();
  const { setValue, getValues } = useFormContext();

  const [ownerID] = useLocalStorage('ownerID', state?.ownerID);

  useEffect(() => {
    if (!getValues('ahv_number')) {
      setValue('ahv_number', '756.', { shouldValidate: false });
    }
  }, [getValues, setValue]);

  const formatAHVNumber = (num: string) => {
    const cleaned = num.replace(/[^0-9]/g, '');
    let formatted = '756.';
    if (cleaned.length > 3) formatted += cleaned.slice(3, 7);
    if (cleaned.length > 7) formatted += '.' + cleaned.slice(7, 11);
    if (cleaned.length > 11) formatted += '.' + cleaned.slice(11, 13);
    return formatted;
  };

  const handleAHVNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    if (value.startsWith('756.')) {
      value = value.slice(4);
    }
    const formattedValue = formatAHVNumber(`756.${value}`);
    setValue('ahv_number', formattedValue, { shouldValidate: true });
  };

  return (
    <Stack
      direction={{
        xs: 'column',
        sm: 'row',
      }}
      spacing={2}
    >
      <RHFAutocomplete
        name="ahv_fund"
        options={ahvFunds}
        label={String(translate('global.formLabels.ahvFund'))}
        disabled={ownerID}
      />
      <RHFTextField
        name="ahv_number"
        label={String(translate('global.formLabels.ahvNumber'))}
        onChange={handleAHVNumberChange}
        disabled={ownerID}
      />
    </Stack>
  );
}
